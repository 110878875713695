module.exports = function (flowGroup) {
  if (!flowGroup || !flowGroup.length) return null;

  let elected;
  if (flowGroup[0].direction === 'in') {
    elected = JSON.parse(JSON.stringify(flowGroup[0]));
    elected.others = flowGroup.slice(1);
  } else if (flowGroup[0].direction === 'out') {
    elected = JSON.parse(JSON.stringify(flowGroup[flowGroup.length - 1]));
    elected.others = flowGroup.slice(0, -1);
  }

  return elected;
};