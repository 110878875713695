const DateTime = require('luxon').DateTime;
const Interval   = require('luxon').Interval;

/**
 * Group passages chained by the same direction
 * @param passages array of passages ordered by day and time ascending
 * @param tolerance seconds accepted to group two passages
 * @returns {Array} array of groups of passages that are in the same direction
 */
module.exports = function (passages, tolerance) {
  let groups = [];
  let current = null;
  passages.forEach(pass => {
    if (!current) {
      // current is empty, let's push the passage
      current = [pass];
    } else {
      let curr = current[current.length - 1];
      let passTime = DateTime.fromISO(pass.date + 'T' + pass.time);
      let currTime = DateTime.fromISO(curr.date + 'T' + curr.time);
      let interval = Interval.fromDateTimes(currTime, passTime);
      if (pass.direction == current[0].direction && interval.count('seconds') < tolerance) {
        // same direction, continue pushing
        current.push(pass)
      } else {
        // opposite direction. let's create a new current
        groups.push(current);
        current = [pass];
      }
    }
  });
  if (current) {
    groups.push(current);
    current = null;
  }
  return groups;
};