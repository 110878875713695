<template>
  <!-- NOTE bg-gray-500 h-screen -->
  <main class="lg:bg-gray-100 min-h-screen">
    <!-- prettier-ignore -->

    <section class="mx-auto lg:p-4" v-if="!cLoadError && company && isManager">
      <div class="lg:max-w-6xl mx-auto">

      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" :to="{name: 'home'}"><a>{{$t('breadcrumb_home')}}</a></router-link>
          <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
          <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
        </ul>
      </nav>

      <sqr-progress v-if="cLoading" />
      <sqr-error :error="cLoadError" />

      <div class="bg-white p-4 border lg:rounded lg:shadow">
        <div class="flex items-baseline">
        <h1 class="text-2xl font-bold py-3 lg:pl-4 lg:mr-1">
            {{ company.name }}
          </h1>
          <router-link :to="{ name: 'company-name', params }">
            <sqr-button icon="edit" color="white"/>
          </router-link>
        </div>
        <div
          class="grid lg:grid-rows-5 lg:grid-cols-4 lg:grid-flow-col md:col-gap-16 links items-end border-t -mx-4 px-4 lg:px-8 border-gray-300"
        >
          <h5
            class="lg:row-start-1 font-bold text-lg  bg-gray-200 lg:bg-transparent text-gray-800 -mx-4 px-4 py-3"
          >
            {{ $t('company_management_planning') }}
          </h5>
          <router-link :to="{ name: 'planning', params }">
            <div class="font-medium">{{ $t('company_planning') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_planning_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-requests', params }">
            <div class="font-medium">{{ $t('company_requests') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_requests_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-holidays', params }">
            <div class="font-medium">{{ $t('company_holidays') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_holidays_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-rights', params }">
            <div class="font-medium">{{ $t('company_rights') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_rights_help') }}
            </div>
          </router-link>
          <h5
            class="lg:row-start-1 font-bold text-lg  bg-gray-200 lg:bg-transparent text-gray-800 -mx-4 px-4 py-3"
          >
            {{ $t('company_reports') }}
          </h5>
          <router-link :to="{ name: 'company-report-work', params }">
            <div class="font-medium">{{ $t('company_report_sheets') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_report_sheets_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-report-vacations', params }">
            <div class="font-medium">{{ $t('company_report_vacations') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_report_vacations_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-report-passages', params }">
            <div class="font-medium">{{ $t('company_report_passages') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_report_passages_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-audit-trail', params }">
            <div class="font-medium">{{ $t('company_audit_trail') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_audit_trail_help') }}
            </div>
          </router-link>
          <h5
            class="lg:row-start-1 font-bold text-lg  bg-gray-200 lg:bg-transparent text-gray-800 -mx-4 px-4 py-3"
          >
            {{ $t('company_accounts_title') }}
          </h5>
          <router-link
            :to="{ name: 'accounts-org', params: { cid, oid: 'default' } }"
          >
            <div class="font-semibold">{{ $t('company_accounts') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_accounts_help') }}
            </div>
          </router-link>
          <router-link
            :to="{ name: 'org', params: { ...params, oid: 'default' } }"
          >
            <div class="font-medium">{{ $t('company_org') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_orgs_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-defaults', params }">
            <div class="font-medium">{{ $t('company_settings_defaults') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_settings_defaults_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-integrations', params }">
            <div class="font-medium">{{ $t('company_integrations') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_integrations_help') }}
            </div>
          </router-link>
          <h5
            class="lg:row-start-1 font-bold text-lg  bg-gray-200 lg:bg-transparent text-gray-800 -mx-4 px-4 py-3"
          >
            {{ $t('company_settings') }}
          </h5>
          <router-link :to="{ name: 'company-name', params }">
            <div class="font-medium">{{ $t('company_settings_name') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_settings_name_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-models', params }">
            <div class="font-medium">{{ $t('company_settings_models') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_settings_models_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-extensions', params }">
            <div class="font-medium">{{ $t('company_settings_extensions') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_extensions_help') }}
            </div>
          </router-link>
          <router-link :to="{ name: 'company-subscription', params }">
            <div class="font-medium">{{ $t('company_subscription') }}</div>
            <div class="text-xs text-gray-700 truncate">
              {{ $t('company_subscription_help') }}
            </div>
          </router-link>
        </div>
      </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
.links a {
  @apply block -mx-4 px-4 py-3 border-t border-gray-200;
}
@screen xl {
  .links a {
    @apply -mx-4 px-4;
  }
}
.links a:hover {
  @apply bg-blue-50;
}
</style>

<script>
import { mapGetters } from 'vuex';
import company from './company';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';
import CompanyName from '@/components/CompanyName';

export default {
  name: 'Company',
  mixins: [company],
  components: { SqrButton, SqrProgress, SqrError, CompanyName },
  computed: {
    ...mapGetters('perms', ['isManager']),
    params() {
      return { cid: this.cid };
    },
  },
};
</script>
