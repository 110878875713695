function newGroup () {
  return {
    in: null,
    out: null
  };
}

const directionFlowGroup = require('./directionFlowGroup');
const electFlowGroup = require('./electFlowGroup');

module.exports = function groupPassages (passages) {
  let directionFlowGroups = directionFlowGroup(passages, 600);
  let electedPassages = directionFlowGroups.map(electFlowGroup);

  let groups = [];
  let current = newGroup();
  let ignored = [];

  electedPassages.forEach((pass) => {
    if (!current.in && pass.direction === 'in') {
      current.in = pass;
    } else if (!current.out && pass.direction === 'out') {
      current.out = pass;
      groups.push(current);
      current = newGroup();
    } else if (pass.direction === 'in') {
      groups.push(current);
      current = newGroup();
      current.in = pass;
    } else {
      groups.push(current);
      current = newGroup();
      current.out = pass;
    }
  });
  if (current.in || current.out) {
    groups.push(current);
  }
  return {groups, ignored};
};