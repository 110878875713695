<template>
  <main class="bg-gray-50">
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'company-report-passages', params: { cid } }"><a>{{ $t('breadcrumb_company_report_passages') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <div class="bg-white max-w-sm p-4">
          <div class="field has-addons">
            <div class="control">
              <sqr-input-date v-model="date" />
            </div>
            <div class="control">
              <sqr-button
                label="buttons_load"
                @click="load()"
                color="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4" v-if="org">
      <div class="container">
        <div v-for="item in org.content" :key="item.id">
          <template v-if="item.type === 'team'">
            <div class="bg-gray-200 text-2xl font-semibold p-4 border-b mt-8">
              {{ item.name }}
            </div>
          </template>
          <template v-if="item.type === 'account'">
            <div>
              <div class="border-b bg-white">
                <div class="lg:flex items-begin">
                  <!-- day -->
                  <div class="lg:w-2/12 p-4">
                    <div class="text-lg">
                      <span class="mr-1">{{ item.givenName }}</span>
                      <span class="font-semibold">{{ item.familyName }}</span>
                    </div>
                  </div>
                  <!-- groups -->
                  <div
                    class="lg:w-10/12 lg:flex lg:flex-wrap"
                    v-if="groupsById[item.id]"
                  >
                    <div
                      v-for="group in groupsById[item.id]"
                      :key="
                        (group.in && group.in.time) ||
                        (group.out && group.out.time)
                      "
                      class="flex lg:border-l border-t lg:border-t-0 p-4 lg:px-8 hover:bg-gray-50"
                    >
                      <div>
                        <div v-if="group.in" class="text-left">
                          <div class="text-xs uppercase text-gray-500">
                            {{ group.in.source }}
                          </div>
                          <div
                            class="time text-center text-xl font-medium tooltip"
                            :data-tooltip="group.in.time"
                          >
                            {{ group.in.time | time }}
                          </div>
                          <div
                            v-for="other in group.in.others"
                            :key="other.id"
                            class="time text-center text-xs tooltip"
                            :data-tooltip="other.time"
                          >
                            {{ other.time | time }}
                          </div>
                        </div>
                        <div v-else class="text-4xl text-red-700 text-bold">
                          ?
                        </div>
                      </div>
                      <div class="text-lg px-8 mt-3">
                        <fa :icon="['fal', 'arrow-right']" />
                      </div>
                      <div>
                        <div v-if="group.out" class="text-right">
                          <div class="text-xs uppercase text-gray-500">
                            {{ group.out.source }}
                          </div>
                          <div
                            class="time text-center text-xl font-medium tooltip"
                            :data-tooltip="group.out.time"
                          >
                            {{ group.out.time | time }}
                          </div>
                          <div
                            v-for="other in group.out.others"
                            :key="other.id"
                            class="time text-center text-xs tooltip"
                            :data-tooltip="other.time"
                          >
                            {{ other.time | time }}
                          </div>
                        </div>
                        <div v-else class="text-4xl text-red-700 text-bold">
                          ?
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fromPairs, sortWith, ascend, prop } from 'ramda';
import { DateTime } from 'luxon';

import groupPassages from 'sqr-wotime-core/passages/groupPassages';

import company from './company';

import CompanyName from '@/components/CompanyName';
import SqrInputDate from '@/sqrd/SqrInputDate';
import SqrButton from '@/sqrd/SqrButton';

import time from '@/filters/time';

function lastDay() {
  const today = DateTime.local().startOf('day');
  const yesterday = today.minus({ day: 1 });
  const lastDay =
    yesterday.weekday > 5 ? yesterday.set({ weekday: 5 }) : yesterday;
  return lastDay.toISODate();
}

const sortPassages = sortWith([
  // ascend(prop('date')),
  ascend(prop('time'))
]);

export default {
  name: 'CompanyReportPassages',
  mixins: [company],
  filters: {
    time,
  },
  components: { SqrInputDate, SqrButton, CompanyName },
  computed: {
    ...mapState('org', {
      org: 'record',
      orgLoading: 'loading',
      orgLoadError: 'error',
    }),
    ...mapState('companyReportPassages', {
      pathes: 'pathes',
      loading: 'loading',
      loadError: 'loadError',
      exists: 'exists',
      docs: 'docs',
    }),
    groupsById() {
      const date = this.date;
      const aids =
        this.org?.content?.filter(i => i.type === 'account').map(i => i.id) ??
        [];
      const pairs = aids.map(aid => {
        const passages = Object.values(this.docs[aid]?.passagesById ?? {});
        const onDate = passages.filter(p => p.date === date) ?? [];
        const sorted = sortPassages(onDate);
        const { groups } = groupPassages(sorted); // ignored
        return [aid, groups];
      });
      return fromPairs(pairs);
    },
  },
  data() {
    return {
      date: lastDay(),
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions('org', { orgSub: 'sub' }),
    ...mapActions('companyReportPassages', ['sub']),
    async load() {
      const week = DateTime.fromISO(this.date).toISOWeekDate().substr(0, 8);
      const org = await this.orgSub({
        path: ['companies', this.cid, 'orgs'],
        id: 'default',
      });
      const content = org?.content ?? [];
      const pairs = content
        .filter(item => item.type === 'account')
        .map(({ id }) => [
          id,
          ['companies', this.cid, 'accounts', id, 'passages', week],
        ]);
      const pathes = fromPairs(pairs);
      this.sub({ pathes });
    },
  },
};
</script>
